<script>
  import { twMerge } from 'tailwind-merge'
  import { colors, baseSize, sizes, baseStyle } from './base-settings.js'

  export let size = baseSize
  export let role = 'img'
  export let color = 'currentColor'

  export let ariaLabel = 'Phone'

  $: fillColor = colors[color] || color
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  fill={fillColor}
  {...$$restProps}
  class={twMerge(baseStyle, sizes[size ?? 'md'], $$props.class)}
  {role}
  aria-label={ariaLabel}
  tabindex="-1"
  viewBox="0 0 103 98"
  on:click
  on:keydown
  on:keyup
  on:focus
  on:blur
  on:mouseenter
  on:mouseleave
  on:mouseover
  on:mouseout
  xmlns:xlink="http://www.w3.org/1999/xlink"
>
  <path
    d="m60.25 35.125c4.3125 3.5625 6.75 8.625 6.75 13.875 0 5.4375-2.4375 10.5-6.75 14.0625-.75.5625-1.875.9375-2.8125.9375-1.3125 0-2.625-.5625-3.5625-1.6875-1.5-1.875-1.3125-4.6875.75-6.375s3.375-4.125 3.375-6.9375c0-2.625-1.3125-5.0625-3.375-6.75s-2.25-4.5-.75-6.375c1.6875-1.875 4.5-2.25 6.375-.75zm11.4375-13.875c8.4375 6.9375 13.3125 16.875 13.3125 27.75s-4.875 21-13.3125 27.75c-.9375.5625-1.875.9375-3 .9375-1.3125 0-2.4375-.5625-3.375-1.6875-1.6875-1.875-1.3125-4.6875.5625-6.1875 6.375-5.25 9.9375-12.9375 9.9375-20.8125 0-8.0625-3.5625-15.5625-9.9375-20.8125-1.875-1.6875-2.25-4.5-.5625-6.375 1.5-1.875 4.3125-2.25 6.375-.5625zm11.4375-13.875c12.5625 10.3125 19.875 25.125 19.875 41.625 0 16.3125-7.3125 31.5-19.875 41.625-.9375.75-1.875 1.125-2.8125 1.125-1.3125 0-2.625-.5625-3.5625-1.6875-1.5-1.875-1.3125-4.6875.5625-6.375 10.5-8.625 16.6875-21.375 16.6875-34.6875 0-13.5-6.1875-26.0625-16.6875-34.6875-1.875-1.6875-2.0625-4.5-.5625-6.375 1.6875-1.875 4.5-2.25 6.375-.5625zm-47.8125 26.4375-9.9375-.9375c-3.75 10.5-3.75 21.9375 0 32.4375l9.9375-.9375c1.875-.1875 3.9375.9375 4.6875 2.8125l6.1875 15.75c.9375 2.0625 0 4.5-1.6875 5.625l-12.75 7.875c-1.6875 1.125-4.125.75-5.625-.75-25.6875-25.6875-25.6875-67.5 0-93.1875 1.5-1.5 3.9375-1.875 5.625-.75l12.75 7.875c1.6875 1.125 2.625 3.5625 1.6875 5.625l-6.1875 15.75c-.75 1.875-2.8125 3-4.6875 2.8125z"
    fill={fillColor}
  ></path>
</svg>
