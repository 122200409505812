<script>
  import { twMerge } from 'tailwind-merge'
  import { baseSize, sizes, baseStyle } from './base-settings.js'

  export let size = baseSize
  export let role = 'img'

  export let ariaLabel = 'TrustPilot'
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  fill="none"
  class={twMerge(baseStyle, sizes[size ?? 'lg'], $$props.class)}
  {role}
  aria-label={ariaLabel}
  tabindex="-1"
  viewBox="0 0 34 34"
  on:click
  on:keydown
  on:keyup
  on:focus
  on:blur
  on:mouseenter
  on:mouseleave
  on:mouseover
  on:mouseout
>
  <path
    d="M33.523 11.969H20.722L16.768 0 12.8 11.97 0 11.957l10.367 7.404-3.966 11.956 10.367-7.392 10.355 7.392-3.954-11.956 10.354-7.392z"
    fill="#04DA8D"
  />
  <path d="m24.058 22.069-.89-2.707-6.4 4.564 7.29-1.857z" fill="#126849" />
</svg>
