<script>
  import {
    StarFilledRound,
    AmazonStore,
    ApplePlayStore,
    GooglePlayStore,
    TrustPilot,
  } from '@/components/icons'

  import Carousel from '@/components/Carousel.svelte'
  import CarouselItem from '@/components/CarouselItem.svelte'

  import ReadMore from '../../ReadMore.svelte'

  export let darkTheme
  const comments = [
    {
      name: 'Denise',
      date: 'August 28',
      title: 'Great little machine',
      description:
        "I have used this on my vehicle. I also used a mechanics diagnostic machine as well to compare results. Both the Fixd and the diagnostic machine gave same codes. I only did that to see how true this machine was. I like the fact that they also send me an email with the Code information and what I can do to fix the issue with my vehicle. This little, tiny machine is amazing and I highly recommend to anyone! this is great for women too this way they don't get ripped off at a mechanic shop",
      icon: TrustPilot,
      source: 'Trustpilot',
      url: 'https://www.trustpilot.com/reviews/66cf40f1589bb05ac56c631d',
      type: 'trustpilot',
    },
    {
      name: 'Stephan Meuchel',
      date: 'March 26',
      title: 'I have owned this gadget from day one',
      description:
        'I have owned this gadget from day one, and I’m so glad I do, it has saved me a ton of money already, and it gives me the ability to double check people trying to sale used vehicles and also mechanics doing there job correctly as well, the features alone that come With just the purchase are the best and if you need extra the premium service has got all the bells and whistles for those who need it more then myself, the company is always updating features and have had zero issues with fixd it’s a must have for sure.',
      icon: TrustPilot,
      source: 'Trustpilot',
      url: 'https://www.trustpilot.com/reviews/6602e547eb71d0a0df458845',
      type: 'trustpilot',
    },
    {
      name: 'Joseph A Bergeron',
      date: 'July 24',
      title: 'I highly recommend the FIXD Sensor',
      description:
        "I highly recommend the FIXD Sensor. All mechanics say this is a great purchase for the DIY people because Service stations are now charging from $100.00 - $190.00 per hour to work on vehicles. They have to do their own diagnostics which takes valuable time. If they have to let a machine do the diagnostics that's $150.00 by itself. When we used the FIXD sensor and we know exactly what needs replacing 8 -10 times we can do this our self. If not we know what the station will charge to replace this item. If your not happy with the sensor they will refund your money no questions asked.",
      icon: TrustPilot,
      source: 'Trustpilot',
      url: 'https://www.trustpilot.com/reviews/66a157104ea93b2e90f83f36',
      type: 'trustpilot',
    },
    {
      name: 'Cody Douglas',
      date: 'September 9',
      title: 'Works as advertised',
      description:
        'Easy to use just download the app, plug the device turn on the engine and follow the easy steps on your phone. You can even clear the check engine light if it’s nothing major. Comes in handy would highly recommend to buy and keep in your glove compartment.',
      icon: AmazonStore,
      source: 'Amazon',
      url: 'https://www.amazon.com/gp/customer-reviews/R3LFHVYLOHWYL8/ref=cm_cr_getr_d_rvw_ttl?ie=UTF8&ASIN=B013RIQMEO',
      type: 'amazon',
    },
  ]

  $: currentType = 'all'
  $: filteredComments =
    currentType === 'all'
      ? comments
      : comments.filter((x) => x.type === currentType)

  const filterComments = (type) => {
    currentType = type
  }

  const filterButtons = [
    {
      type: 'all',
      icon: null,
      text: 'All Reviews',
      rating: 4.6,
    },
    {
      type: 'apple',
      icon: ApplePlayStore,
      text: 'Apple App Store',
      rating: 4.6,
    },
    {
      type: 'amazon',
      icon: AmazonStore,
      text: 'Amazon',
      rating: 4.6,
    },
    {
      type: 'google',
      icon: GooglePlayStore,
      text: 'Google Play',
      rating: 4.6,
    },
  ]
</script>

<div class="rounded max-w-[900px] mx-auto">
  <div class="bg-green rounded-t-lg py-0.5 font-inter text-sm">
    <div class="max-w-screen-md mx-auto">
      <Carousel
        showPagination={false}
        showArrows={false}
        arrowColor="white"
        splideOptions={{
          classes: 'flex',
          perPage: 4,
          width: '768px',
          padding: 32,
          breakpoints: {
            768: {
              perPage: 2,
              fixedWidth: 170,
              gap: '16px',
              arrows: true,
            },
          },
        }}
      >
        {#each filterButtons as button}
          <CarouselItem>
            <div
              class="flex items-center justify-center"
              on:click={() => filterComments(button.type)}
              on:keydown={() => filterComments(button.type)}
              role="button"
              tabindex="-1"
            >
              <div
                class="py-2 border-b-2 flex items-center gap-3"
                class:border-transparent={currentType !== button.type}
              >
                {#if button.icon}
                  <svelte:component
                    this={button.icon}
                    color="black"
                    size="md"
                  />
                {/if}
                <p class="text-white">
                  {button.text}
                  <span class="font-semibold">{button.rating}</span>
                </p>
              </div>
            </div>
          </CarouselItem>
        {/each}
      </Carousel>
    </div>
  </div>
  <div
    class="rounded text-left p-6 mb-5 {darkTheme
      ? 'bg-[#000000]'
      : 'bg-[#f3f3f3]'}"
  >
    <p class="text-xl mb-1">Overall Rating</p>
    <div class="flex items-center gap-3">
      <p class="text-xl font-bold">4.6</p>
      <div class="flex">
        {#each new Array(5) as _}
          <StarFilledRound color="yellow" size="md" />
        {/each}
      </div>
      <p class="text-xs text-grey">46,903 reviews</p>
    </div>
  </div>
</div>

<div class="space-y-5 max-w-[900px] mx-auto">
  {#each filteredComments as comment}
    <div class="rounded p-6 {darkTheme ? 'bg-[#000000]' : 'bg-[#f3f3f3]'}">
      <header class="text-left">
        <div class="mb-3">
          <a
            href={comment.url}
            class="font-semibold text-sm no-underline hover:underline text-black"
            >{comment.name}</a
          >
          <p class="text-xs">{comment.date}</p>
        </div>
        <div class="flex mb-2">
          {#each new Array(5) as _}
            <StarFilledRound color="yellow" size="md" />
          {/each}
        </div>
      </header>
      <div class="text-center leading-6 mb-3">
        {#if comment.title}
          <p class="font-semibold">{comment.title}</p>
        {/if}
        <p class:text-left={!comment.title}>
          <ReadMore text={comment.description} length={325} />
        </p>
      </div>
      <a
        class="text-left no-underline group"
        href={comment.url}
        target="_blank"
        rel="noreferrer"
      >
        <div class="flex items-center">
          <svelte:component this={comment.icon} color={'green'} size="xl" />
          <div class="leading-5 text-center ml-3">
            <p class="text-grey text-xs">Posted on</p>
            <p class="text-green text-sm group-hover:underline">
              {comment.source}
            </p>
          </div>
        </div>
      </a>
    </div>
  {/each}
</div>
