<script>
  import { twMerge } from 'tailwind-merge'
  import { colors, baseSize, sizes, baseStyle } from './base-settings.js'

  export let size = baseSize
  export let role = 'img'
  export let color = 'currentColor'

  export let ariaLabel = 'ClipBoard'

  $: fillColor = colors[color] || color
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  fill={fillColor}
  {...$$restProps}
  class={twMerge(baseStyle, sizes[size ?? 'md'], $$props.class)}
  {role}
  aria-label={ariaLabel}
  tabindex="-1"
  viewBox="0 0 72 96"
  on:click
  on:keydown
  on:keyup
  on:focus
  on:blur
  on:mouseenter
  on:mouseleave
  on:mouseover
  on:mouseout
  xmlns:xlink="http://www.w3.org/1999/xlink"
>
  <path
    d="M63 12C67.875 12 72 16.125 72 21V87C72 92.0625 67.875 96 63 96H9C3.9375 96 0 92.0625 0 87V21C0 16.125 3.9375 12 9 12H18.9375C21.5625 5.0625 28.125 0 36 0C43.6875 0 50.25 5.0625 52.875 12H63ZM36 12C32.625 12 30 14.8125 30 18C30 21.375 32.625 24 36 24C39.1875 24 42 21.375 42 18C42 14.8125 39.1875 12 36 12ZM52.875 49.3125C54.5625 47.4375 54.1875 44.625 52.125 43.125C50.25 41.4375 47.4375 41.8125 45.9375 43.875L32.25 61.125L25.3125 55.125C23.4375 53.625 20.625 53.8125 18.9375 55.6875C17.4375 57.5625 17.625 60.375 19.5 62.0625L30 71.0625C30.75 71.625 31.875 72 33 72C33 72 33.1875 72 33.375 72C34.5 72 35.625 71.25 36.375 70.3125L52.875 49.3125Z"
    fill={fillColor}
  ></path>
</svg>
